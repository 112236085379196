@import 'media-query.mixins';
@import 'util.mixins';

.location-input {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  container-name: location-input-container;
  container-type: inline-size;

  input {
    height: var(--filtersHeight, 35px);
    padding: var(--locationSelectorInputPadding, 0 0.5rem);
    width: 100%;
    border-radius: var(--inputBoxBorderRadius, var(--borderRadius));
    font-size: inherit;
    // @include for-tablet-portrait-up {
    //   font-size: var(--textSize);
    // }
  }
}

.location-input-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  fa-icon {
    width: min-content;
  }
}

button.ush-near-me-button {
  height: var(--filtersHeight, 35px);
  width: max-content;
  font-family: inherit;
  @if mixin-exists($name: locationSelectorNearMeMixin) {
    @include locationSelectorNearMeMixin;
  }

  span {
    display: none;
  }

  fa-icon {
    font-size: 20px;
    display: flex;
  }

  @container location-input-container (width > #{$sm}) {
    fa-icon {
      display: none;
    }
    span {
      display: block;
    }
  }
}

.input-icon {
  height: min-content;
  display: none;
  position: absolute;
  color: var(--locationSelectorIconColor);
  text-shadow: var(--locationSelectorIconTextShadow);
  height: 100%;
  align-items: center;
  right: 10px;

  @if var(--locationSelectorIconName) == 'map-marker' {
    &.marker-icon {
      display: flex;
    }
  } @else {
    &.search-icon {
      display: flex;
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
